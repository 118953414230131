<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
     
      <b-col lg="12" sm="12">
        <statistic-card-with-area-chart
          v-if="data.subscribersGained"
          icon="UsersIcon"
          :statistic="kFormatter(363)"
          statistic-title="All members"
          :chart-data="data.subscribersGained.series"
        />
      </b-col>
   
    </b-row>

    <b-card-code no-body title="New Adoption">
      <analytics-table />
    </b-card-code>

    <b-row>
      <b-col cols="12"> </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "./AnalyticsTimeline.vue";
import AnalyticsSalesRadarChart from "./AnalyticsSalesRadarChart.vue";
import AnalyticsAppDesign from "./AnalyticsAppDesign.vue";
import AnalyticsTable from "./TableAnalytics.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,
    AnalyticsTable,
    BCardCode,
    BTable,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
  methods: {
    kFormatter,
  },
};
</script>
